

























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
    apiDistributionGetFans,
    apiDistributionGetFansLists
} from '@/api/distribution/distribution'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import FansPane from '@/components/user/fans-pane.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsPagination,
        FansPane,
        ExportData
    }
})
export default class LsFanList extends Vue {
    apiDistributionGetFansLists = apiDistributionGetFans

    /** S Data **/
    // 邀请人信息
    user_info = {
        user_info: {
            nickname: '',
            sn: ''
        },
        fans: '',
        fans_distribution: '',
        fans_one: '',
        fans_two: ''
    }

    activeName: any = '1' //全部;

    tabs = [
        {
            label: '下一级',
            name: '1'
        },
        {
            label: '下二级',
            name: '2'
        }
    ]

    tabCount = {
        1: '0', //全部
        2: '0' //待支付
    }

    // 查询表单
    form = {
        user_id: '', // 用户id
        user_info: ''
    }

    pager: RequestPaging = new RequestPaging()
    /** E Data **/

    /** S Methods **/
    // 重置
    onReset() {
        this.form.user_info = ''
        this.getList()
    }

    // 用户信息
    getUserInfo() {
        apiDistributionGetFans({ user_id: this.form.user_id }).then(
            (res: any) => {
                this.user_info = res
            }
        )
    }
    // 邀请列表
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiDistributionGetFansLists,
                params: {
                    level: this.activeName,
                    ...this.form
                }
            })
            .then((res: any) => {
                this.tabCount['1'] = res.extend.one
                this.tabCount['2'] = res.extend.two
            })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        if (query.id) {
            this.$set(this.form, 'user_id', query.id)
        }

        setTimeout(() => {
            this.getUserInfo()
            this.getList()
        }, 50)
    }
    /** E Life Cycle **/
}
